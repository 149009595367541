import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import tenantConfig from './config/tenantConfig.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: tenantConfig.basic.allowedLanguages[0].value,
    fallbackLng: 'en',
    load: 'languageOnly',
    nonExplicitSupportedLngs: true,
    react: {
      wait: true,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
