import moment from 'moment';

export const getValidEpochDate = (timeStamp) => timeStamp
  && new Date(
    moment(timeStamp.length >= 12 ? +timeStamp : +timeStamp * 1000).format(
      'ddd MMM DD YYYY h:mm:ss a',
    ),
  );

export const stringDate = (date) => moment(date).format('YYYY-MM-DD HH:mm');

export const stringDateWithSeconds = (date) => moment(date).format('YYYY-MM-DD HH:mm:ss');

// moment('24/12/2019 09:15:00', "DD MM YYYY hh:mm:ss");
export const getValidEpochNumber = (date) => (date ? moment(date).unix() : 0);

export const stringDateWithoutHoursMins = (date) => moment(date).format('YYYY-MM-DD');

export const getValidTimeBasedDate = (timeStamp) => {
  const date = new Date();
  const times = timeStamp && timeStamp.match(/.{1,2}/g);
  date.setHours(parseInt(times[0], 10));
  date.setMinutes(parseInt(times[1], 10));
  return timeStamp ? date : timeStamp;
};

export const getTimeFromDate = (date) => {
  if (date) {
    const hrs = `0${date.getHours()}`;
    const min = `0${date.getMinutes()}`;
    return `${hrs.substr(hrs.length - 2)}${min.substr(min.length - 2)}`;
  }
  return false;
};

export const getDateDisplayFormat = (date, format = 'MMM Do YYYY, HH:mm') => moment(date).format(format);

export const getDurationWithDiff = (start, end, format = 'hours') => {
  if (!start || !end) return false;

  const statVal = typeof start === 'number' ? moment(getValidEpochDate(start)) : moment(start);
  const endVal = typeof end === 'number' ? moment(getValidEpochDate(end)) : moment(end);
  return moment.duration(endVal.diff(statVal)).as(format);
};

export const addDuration = (date, duration, isDateType) => {
  if (!date) return '';
  return isDateType ? moment(date).add(...duration).toDate() : moment(date).add(...duration);
};

export const setExactTime = (date = new Date(), time) => (date ? moment(date.setHours(...time)).toDate() : '');

const getValidStartDate = (...rest) => {
  const [startVal, setMethod, startFieldName, isPastTimeAvailable] = rest;
  let validStartDate = startVal;
  const currentDate = new Date();
  const validStartDateCheckRule = !isPastTimeAvailable
    && startVal
    && ((startVal?.getTime() < currentDate.getTime()) || (startVal.getFullYear() > 2070));

  if (validStartDateCheckRule) {
    setTimeout(() => setMethod(startFieldName, currentDate), 0);
    validStartDate = currentDate;
  }

  return validStartDate;
};

export const validateStartDate = (...rest) => {
  const [
    startVal,
    endVal,
    setMethod,
    endFieldName,
    startFieldName,
    isPastTimeAvailable,
    isSameAllowed] = rest;
  let validStartDate = getValidStartDate(
    startVal, setMethod, startFieldName, isPastTimeAvailable,
  );

  validStartDate = validStartDate ? moment(validStartDate).set('second', 0).set('millisecond', 0).toDate() : null;
  const endValModified = endVal ? moment(endVal).set('second', 0).set('millisecond', 0).toDate() : null;

  const startEndTimeCheckRule = validStartDate
    && endValModified
    && (
      (!isSameAllowed && validStartDate?.getTime() >= endValModified?.getTime())
    || (isSameAllowed && validStartDate?.getTime() > endValModified?.getTime())
    );

  if (startEndTimeCheckRule) {
    setTimeout(() => setMethod(endFieldName, ''), 0);
  }

  return validStartDate;
};

const getValidEndDate = (...rest) => {
  const [endVal, setMethod, endFieldName, isPastTimeAvailable] = rest;
  let validEndDate = endVal;
  const currentDate = new Date();
  currentDate.setSeconds(0);
  const validEndDateCheckRule = !isPastTimeAvailable
    && endVal
    && ((endVal?.getTime() < currentDate.getTime()) || (endVal.getFullYear() > 2070));

  if (validEndDateCheckRule) {
    setTimeout(() => setMethod(endFieldName, currentDate), 0);
    validEndDate = currentDate;
  }

  return validEndDate;
};

export const validateEndDate = (...rest) => {
  const [
    endVal,
    startVal,
    setMethod,
    endFieldName,
    isPastTimeAvailable,
    type,
    range,
    isSameAllowed,
  ] = rest;
  let validEndDate = getValidEndDate(endVal, setMethod, endFieldName, isPastTimeAvailable);

  validEndDate = validEndDate ? moment(validEndDate).set('second', 0).set('millisecond', 0).toDate() : null;
  const startValModified = startVal ? moment(startVal).set('second', 0).set('millisecond', 0).toDate() : null;

  const startEndTimeCheckRule = validEndDate
    && startValModified && ((!isSameAllowed && validEndDate?.getTime()
  <= startValModified?.getTime()) || (isSameAllowed && validEndDate?.getTime()
  < startValModified?.getTime()));

  if (startEndTimeCheckRule) {
    const addType = type || 'minutes';
    setTimeout(() => setMethod(
      endFieldName, moment(startVal).add(range || 1, addType).toDate(),
    ), 0);
    validEndDate = moment(startVal).add(1, addType).toDate();
  }

  return validEndDate;
};

const isTimeValValid = (minDate, isDependent) => {
  if (!minDate) {
    return true;
  }
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);

  if (minDate.getTime() < today.getTime() && !isDependent) {
    return true;
  }
  return false;
};

export const getValidDisplayTime = (timeVal, selectedDate, minDate, isDependent) => {
  if (isTimeValValid(minDate, isDependent)) {
    return timeVal;
  }

  const currentDate = isDependent ? moment(minDate).add(1, 'minutes').toDate() : new Date();
  const selectedDateClone = new Date(selectedDate.getTime());
  const times = timeVal && timeVal.split(':');
  selectedDateClone.setHours(parseInt(times[0], 10));
  selectedDateClone.setMinutes(parseInt(times[1], 10));
  return selectedDateClone.getTime() < currentDate.getTime() ? moment(currentDate).format('HH:mm') : timeVal;
};

export const startOfDate = (days, startOf) => moment().startOf(startOf).subtract(days, 'days').toDate();

export const endOfDate = (days, startOf) => moment().endOf(startOf).subtract(days, 'days').toDate();

export const getDaysDuration = (date1, date2) => {
  const differenceInTime = date2.getTime() - date1.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return differenceInDays;
};
