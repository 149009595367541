import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import { createSelector } from 'reselect';
import Router from './Router';

import { privateRoutes } from './Route.config';
import { getAuthorizedRoutes } from '../module/auth/Utils';
import { loadAuthorizedRoles } from '../common/action/localStore';

const authRoutesSelect = createSelector(
  (state) => state.LocalStoreReducer.authRoles,
  (authRoles) => getAuthorizedRoutes(privateRoutes, authRoles),
);

const AppRoutes = ({ publicRoutes, history }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.oidc.user);
  const authRoutes = useSelector(authRoutesSelect);
  const isAuthError = useSelector((state) => state.LocalStoreReducer.authError);
  const authRoles = useSelector((state) => state.LocalStoreReducer.authRoles);

  useEffect(() => {
    !isAuthError && !authRoles && currentUser && dispatch(loadAuthorizedRoles());
  }, []);

  // console.log('authRoutes', authRoutes);

  return (
    <>
      <Switch>
        {
          publicRoutes.map((route) => (
            <Router {...{
              path: route.path,
              exact: route.exact,
              component: route.component,
              key: route.id,
              routeConfig: route,
              history,
            }}
            />
          ))
        }
        {
          !currentUser ? (
            <Redirect {...{
              to: {
                pathname: '/signin',
                state: { from: window.location.pathname },
              },
            }}
            />
          ) : (
            authRoutes.map((route) => (
              <Router {...{
                path: route.path,
                exact: route.exact,
                component: route.component,
                key: route.id,
                routeConfig: route,
                history,
              }}
              />
            ))
          )
        }
      </Switch>
    </>
  );
};

AppRoutes.propTypes = {
  publicRoutes: PropTypes.instanceOf(Array).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default AppRoutes;
