import {
  MAPPING_WITH_STORE, MENU_ITEM, ALL_PRODUCTS, ALL_CATEGORIES, AUTH_ROLES, AUTH_NAVIGATIONS,
  AUTH_ERROR,
  GET_RESTAURANT_GROUP,
  POST_RESTAURANT_GROUP,
  GET_RESTAURANT_GROUP_LIST,
  DELETE_RESTAURANT_GROUP,
  GET_RESTAURANT_GROUP_BY_STORE,
  GET_ITEM_GROUP_LIST,
  POST_ITEM_GROUP,
  GET_ITEM_GROUP,
  DELETE_ITEM_GROUP,
} from '../constants';

const initialState = {
  mappingStore: {},
  menuItems: null,
  allProducts: null,
  allCategories: null,
  productItemList: null,
  authRoles: null,
  authNavigations: null,
  authError: false,
  getRestaurantGroup: null,
  postRestaurantGroup: null,
  getRestaurantGroupList: null,
  deleteRestaurantGroup: null,
  getRestaurantGroupListByStore: null,
  getItemGroupList: null,
  postItemGroup: null,
  getItemGroup: null,
  deleteItemGroup: null,
};

export default function LocalStoreReducer(state = initialState, action) {
  switch (action.type) {
    case MAPPING_WITH_STORE:
      return {
        ...state,
        mappingStore: action.payLoad,
      };
    case MENU_ITEM:
      return {
        ...state,
        menuItems: action.payLoad,
      };
    case ALL_PRODUCTS:
      return {
        ...state,
        allProducts: action.payLoad,
      };
    case ALL_CATEGORIES:
      return {
        ...state,
        allCategories: action.payLoad,
      };
    case AUTH_ROLES:
      return {
        ...state,
        authRoles: action.payLoad,
      };
    case AUTH_NAVIGATIONS:
      return {
        ...state,
        authNavigations: action.payLoad,
      };
    case AUTH_ERROR:
      return {
        ...state,
        authError: action.payLoad,
      };
    case GET_RESTAURANT_GROUP:
      return {
        ...state,
        getRestaurantGroup: action.payLoad,
      };
    case DELETE_RESTAURANT_GROUP:
      return {
        ...state,
        deleteRestaurantGroup: action.payLoad,
      };

    case POST_RESTAURANT_GROUP:
      return {
        ...state,
        postRestaurantGroup: action.payLoad,
      };
    case GET_RESTAURANT_GROUP_LIST:
      return {
        ...state,
        getRestaurantGroupList: action.payLoad,
      };
    case GET_ITEM_GROUP_LIST:
      return {
        ...state,
        getItemGroupList: action.payLoad,
      };

    case GET_RESTAURANT_GROUP_BY_STORE:
      return {
        ...state,
        getRestaurantGroupListByStore: action.payLoad,
      };
    case POST_ITEM_GROUP:
      return {
        ...state,
        postRestaurantGroup: action.payLoad,
      };
    case GET_ITEM_GROUP:
      return {
        ...state,
        getItemGroup: action.payLoad,
      };
    case DELETE_ITEM_GROUP:
      return {
        ...state,
        deleteItemGroup: action.payLoad,
      };
    default:
      return state;
  }
}
