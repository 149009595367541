export const LOG_ERROR = 'LOG_ERROR';
// export const FETCH_ALL_STORE = 'STORE_FETCH_ALL_STORE';
export const MAPPING_WITH_STORE = 'MAPPING_WITH_STORE';
export const FETCH_CONFIG_INFO = 'FETCH_CONFIG_INFO';
export const IS_LOADING = 'IS_LOADING';
export const BATCH_JOB = 'BATCH_JOB';
export const MENU_ITEM = 'MENU_ITEM';
export const ALL_PRODUCTS = 'ALL_PRODUCTS';
export const ALL_CATEGORIES = 'ALL_CATEGORIES';
export const CHANNEL_SERVICE_MENU_ITEM = 'CHANNEL_SERVICE_MENU_ITEM';
export const AUTH_ROLES = 'AUTH_ROLES';
export const AUTH_NAVIGATIONS = 'AUTH_NAVIGATIONS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const GET_RESTAURANT_GROUP = 'GET_RESTAURANT_GROUP,';
export const POST_RESTAURANT_GROUP = 'POST_RESTAURANT_GROUP,';
export const GET_RESTAURANT_GROUP_LIST = 'GET_RESTAURANT_GROUP_LIST';
export const DELETE_RESTAURANT_GROUP = 'DELETE_RESTAURANT_GROUP';
export const GET_RESTAURANT_GROUP_BY_STORE = 'GET_RESTAURANT_GROUP_BY_STORE';
export const GET_ITEM_GROUP_LIST = 'GET_ITEM_GROUP_LIST';
export const POST_ITEM_GROUP = 'POST_ITEM_GROUP';
export const GET_ITEM_GROUP = 'GET_ITEM_GROUP';
export const DELETE_ITEM_GROUP = 'DELETE_ITEM_GROUP';
